export const SET_SNACKBAR = "GLOBAL/SET_SNACKBAR";

export const SET_MODAL_CONFIRM = "GLOBAL/SET_MODAL_CONFIRM";

export const SET_MODAL_CONFIRM_ACCEPTED = "GLOBAL/SET_MODAL_CONFIRM_ACCEPTED";

export const SET_MODAL_CREATE_UPDATE = "GLOBAL/SET_MODAL_CREATE_UPDATE";

export const SET_MODAL_DATA_FORM = "GLOBAL/SET_MODAL_DATA_FORM";

export const GET_METAS = "GLOBAL/GET_METAS";

export const GET_METAS_CONCLUIDAS = "GLOBAL/GET_METAS_CONCLUIDAS";

export const GET_TAREFAS = "GLOBAL/GET_TAREFAS";

export const GET_TAREFAS_CONCLUIDAS = "GLOBAL/GET_TAREFAS_CONCLUIDAS";

export const GET_CHAT = "GLOBAL/GET_CHAT";
